import * as React from 'react';
import { Heading } from '../ui/text/Heading';
import { Accordion, AccordionTab } from '../ui/accordion/Accordion';
import { useAccordion } from '../../utils/hooks/useAccordion';
import { SiteContext } from '../../utils/context/SiteContext';
import { Paragraph } from '../ui/text/Paragraph';
import { ButtonGroup } from '../ui/button/ButtonGroup';
import { Button } from '../ui/button/Button';
import styled from 'styled-components';

export const AccordionRoadToSold: React.FC = () => {
  const { routeConfig } = React.useContext(SiteContext);
  const { open, onTabClick } = useAccordion();
  return (
    <>
      <AccordionWrapper>
        <Accordion>
          <AccordionTab
            open={open === 1}
            tabOnClick={() => onTabClick(1)}
            /* headingColor="#fff" */
            heading="Kan jeg delta om jeg allerede har en megler?"
            className="accordion"
          >
            {/*         <Heading tag="h4">tittel</Heading> */}
            <Paragraph className="text">
              ​Det kan du. Samtidig er det viktig å være klar over at hvis du er
              kontraktsbundet til en annen megler, kan det påløpe kostnader ved
              å bryte avtalen. PrivatMegleren vil ikke dekke disse kostnadene.
              Les mer om dette i våre vilkår{' '}
              <a href="/eiendomsmegler/solgt/vilkaar">
                <span className="red underline">her</span>
              </a>
              .{' '}
            </Paragraph>
          </AccordionTab>
          <AccordionTab
            open={open === 2}
            tabOnClick={() => onTabClick(2)}
            heading="Når får jeg vite om jeg er en av de heldige deltakerne?"
            className="accordion"
          >
            <Paragraph className="text">
              Vi vil vurdere søknader fortløpende, og ta den endelige
              avgjørelsen i løpet av mars. Alle som søker vil få svar når
              avgjørelsen er tatt, om man er blant de utvalgte eller ikke.
            </Paragraph>
          </AccordionTab>
          <AccordionTab
            open={open === 3}
            tabOnClick={() => onTabClick(3)}
            heading="Når skjer innspillingen av filmene?"
            className="accordion"
          >
            <Paragraph className="text">
              Vi planlegger tidspunktene for filminnspilling sammen med deg.
              Siden vi skal følge deg på veien mot solgt, blir oppstart idet vi
              setter i gang salgsprosessen. Skal vi for eksempel selge for deg i
              mars, er det naturlig at vi kommer på vårt første besøk da.
              Deretter ønsker vi oss et intervju underveis i prosessen samt et
              sluttintervju etter at vi har solgt. Vi finner tidspunkt og datoer
              som passer deg.
            </Paragraph>
          </AccordionTab>
        </Accordion>
      </AccordionWrapper>
    </>
  );
};

const AccordionWrapper = styled.div`
  .accordion {
    text-align: left;
  }
  .text {
    text-align: left;
  }
`;
