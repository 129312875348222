import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { useScrollToElement } from '../../../../utils/hooks/useScrollToElement';
import { Container } from '../../../layout/Container';
import { Section } from '../../../layout/Section';
import { AccordionBenefits } from '../../../partials/AccordionBenefits';
import { AccordionRoadToSold } from '../../../partials/AccordionRoadToSold';
import { Button } from '../../../ui/button/Button';
import ButtonGroupGrid from '../../../ui/button/ButtonGroupGrid';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import JotformEmbed from 'react-jotform-embed';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { useProgressiveImage } from '../../../../utils/hooks/useProgressiveImage';
import { animated, useSpring } from 'react-spring';
import { Seo } from '../../../../utils/seo/Index';
import { routeConfig } from '../../../../__config__/routes';

const VeienTilSolgt: React.FC = () => {
  const rsvpRef = React.useRef<HTMLDivElement>(null);
  const hvafaarduRef = React.useRef<HTMLDivElement>(null);
  const faqRef = React.useRef<HTMLDivElement>(null);

  const { scrollTo } = useScrollToElement();

  return (
    <VeienTilSolgtStyle>
      <Seo
        title="Veien til solgt - PrivatMegleren"
        description="Delta i konkurransen og få et boligsalg gratis som sikrer at boligen, leiligheten, eller eiendommen din blir solgt."
        url={routeConfig?.veientilsolgt.url}
        image="https://cdn.reeltime.no/pm_assets/kampanjer/veien-til-solgt/veientilsolgt.jpg"
        imageTitle="veien-til-solgt-fa-solg-bolig-leilighet-eller-eiendom-med-privatmegleren"
      />
      <Section className="form-section" style={{ paddingBottom: '10px' }}>
        <Top
          style={{ paddingBottom: '1em' }}
          url="https://cdn.reeltime.no/pm_assets/kampanjer/veien-til-solgt/konseptlogo_stor.jpg"
        />
      </Section>

      <Container>
        <Paragraph center={true} className="introText">
          Har du prøvd å selge uten hell? Da kan vi hjelpe. Akkurat nå gir vi
          bort hele vår leveranse til én heldig utvalgt. Det første steget på
          veien mot solgt, er å la våre eiendomsmeglere vurdere saken din.
        </Paragraph>
        <Paragraph center={true} className="introText">
          Les videre og meld deg på!
        </Paragraph>
      </Container>
      <ButtonGroupGrid
        style={{ paddingTop: '2em' }}
        className="col-4 blikontaktet-grid"
      >
        <Button
          className="buttons"
          type="secondary"
          colorTheme="gold"
          fullWidth={true}
          center={true}
          onClick={() => {
            if (typeof scrollTo === 'function') {
              scrollTo({
                ref: rsvpRef,
                distanceTop: 100,
                behaviour: 'smooth'
              });
            }
          }}
        >
          Påmelding
        </Button>
        <Button
          className="buttons"
          type="secondary"
          colorTheme="gray"
          fullWidth={true}
          center={true}
          onClick={() => {
            if (typeof scrollTo === 'function') {
              scrollTo({
                ref: hvafaarduRef,
                distanceTop: 100,
                behaviour: 'smooth'
              });
            }
          }}
        >
          Hva får du?
        </Button>
        <Button
          className="buttons"
          as="link"
          type="secondary"
          colorTheme="gray"
          fullWidth={true}
          center={true}
          to="/eiendomsmegler/solgt/vilkaar"
        >
          Vilkår
        </Button>
        <Button
          className="fritidsbrosjyre buttons"
          type="secondary"
          colorTheme="gray"
          fullWidth={true}
          center={true}
          onClick={() => {
            if (typeof scrollTo === 'function') {
              scrollTo({
                ref: faqRef,
                distanceTop: 100,
                behaviour: 'smooth'
              });
            }
          }}
        >
          FAQ
        </Button>
      </ButtonGroupGrid>

      <Container style={{ paddingBottom: '2em', paddingTop: '2.5em' }}>
        <Heading center tag="h2">
          Bli med i vår filmserie
        </Heading>

        <Paragraph center={true}>
          Vi søker eiere av en fritidsbolig som er til salgs, som av ulike
          årsaker ikke har blitt solgt. Hvis vi tar oppdraget ditt, får du hele
          salget dekket. Prosessen vil bli filmet, slik at din historie kan
          inspirere andre til å ta de riktige grepene for å komme i mål med
          salget. Sammen skal vi ta seerne med hele veien til solgt.
        </Paragraph>
      </Container>

      <Section
        className="section info-section"
        style={{ paddingTop: '1em', paddingBottom: '1em' }}
      >
        <Grid className="grid">
          <Column>
            <Heading tag="h2" className="title" center={true}>
              Hvorfor gjør vi dette?
            </Heading>

            <Paragraph center={true} className="form-text custom">
              Ved å vise hva vi faktisk gjør og hvordan vi gjør det, er målet
              vårt å få folk til å velge oss. Dette er ingen hemmelighet.
            </Paragraph>
            <Paragraph center={true} className="form-text custom">
              Det er heller ingen hemmelighet at vi lever av fornøyde kunder, og
              fornøyde kunder får vi ved å få solgt boliger og andre eiendommer
              til riktig pris – uansett markedssituasjon. Å gi noen en ny sjanse
              og hjelp i en frustrerende situasjon, er derfor noe vi virkelig
              ser frem til. Eiendom handler om penger, men også om mennesker,
              liv og drømmer.
            </Paragraph>

            <Heading
              style={{ paddingBottom: '0' }}
              tag="h2"
              className="title"
              center={true}
            >
              Om utvelgelsen
            </Heading>
            <Paragraph center={true} className="form-text custom">
              Når du sender inn din søknad, vil vårt fagteam vurdere saken din.
              Det er en rekke ulike kriterier vi ser etter som tilstand på
              eiendommen, tidligere salgsprosess og at du synes det er greit å
              delta på film.
            </Paragraph>

            <Paragraph center={true} className="form-text custom">
              Husk, at vi skal velge ut fire eiendommer, så nåløyet er trangt.
            </Paragraph>

            <Paragraph center={true} className="form-text custom">
              Alle som søker vil få svar.
            </Paragraph>
            <Heading tag="h2" className="title" center={true}>
              Vilkår og juridisk
            </Heading>
            <Paragraph center={true} className="form-text custom">
              For å delta i prosjektet må du samtykke til våre råd og utførelse
              av salget. Du må også samtykke til at vi kan filme deg/dere og
              boligen/fritidsboligen.
            </Paragraph>
            <Paragraph center={true} className="form-text custom">
              Siden det er snakk om salg av bolig eller fritidsbolig, er det
              pålagt med juridisk informasjon. Den kan du finne{' '}
              <a href="/eiendomsmegler/solgt/vilkaar">
                <span className="red underline"> her.</span>
              </a>
            </Paragraph>
          </Column>
          <Column>
            <HvaaFaarDu ref={hvafaarduRef}>
              <Heading
                style={{ paddingBottom: '0' }}
                tag="h2"
                className="title"
                center={true}
              >
                Hva får du?
              </Heading>
            </HvaaFaarDu>
            <Paragraph center={true} className="form-text custom">
              Hele PrivatMeglerens leveranse ved salg av eiendom
              (bolig/fritidsbolig) er inkludert.
            </Paragraph>

            <Paragraph center={true} className="form-text custom">
              Leveransen inkluderer blant annet stylingtjenester, fotograf,
              annonsetekster og markedsføring. Vi dekker også eventuelle
              oppgraderinger vi ser for oss bør gjøres til fordel for salget.
              Sammen finner vi ut av hva nettopp du har behov for.
            </Paragraph>
            <Paragraph center={true} className="form-text custom">
              Les mer om hva du får, regler og vilkår{' '}
              <a href="/eiendomsmegler/solgt/vilkaar">
                <span className="red underline"> her.</span>
              </a>
            </Paragraph>
            <Faq ref={faqRef}>
              <Heading style={{ marginBottom: '0' }} tag="h2">
                FAQ
              </Heading>
              <AccordionRoadToSold />
            </Faq>
          </Column>
        </Grid>
      </Section>

      <Section style={{ padding: '0' }} ref={rsvpRef}>
        <Heading style={{ marginTop: '35px' }} center tag="h2">
          Påmelding
        </Heading>
        <JotformEmbed
          style={{
            minWidth: '100%',
            height: '100%',
            border: 'none'
          }}
          src="https://form.jotform.com/230151747655356"
        />
      </Section>
    </VeienTilSolgtStyle>
  );
};

const HvaaFaarDu = styled.div``;
const Faq = styled.div``;
const VeienTilSolgtStyle = styled.div`
  .introText {
    font-family: 'Times New Roman', sans-serif;
    font-size: 28px;
  }
  .red {
    color: red;
  }
  .underline {
    text-decoration: underline;
  }
  .title {
    font-size: 38px;
  }
  .buttons {
    &:hover  {
      background-color: ${({ theme }) => theme.colors.coral};
      cursor: pointer;
    }
  }
  .info-section {
    background-color: ${({ theme }) => theme.colors.gray};
  }

  @media all and (max-width: 600px) {
    .blikontaktet-grid {
      grid-template-columns: repeat(2, max-content);
    }
  }

  @media all and (max-width: 350px) {
    .blikontaktet-grid {
      grid-template-columns: repeat(1, max-content);
    }
  }
`;

const BigSpace = styled.div`
  position: relative;
  height: 450px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 2em;
  .custom {
    padding: 0 3rem;
    text-align: center;
  }

  @media all and (min-width: 600px) {
    .custom {
      padding: 0 3rem;
      text-align: left;
    }
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: -15px;
    font-weight: bold;
    font-size: 1.2rem;
  }

  @media all and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
`;

const Top: React.FC<{ url: string; style: any }> = ({ url, style }) => {
  const { loaded } = useProgressiveImage({
    url
  });
  const animatedProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded ? 1 : 0 }
  });

  return (
    <StyledTop style={animatedProps}>
      <img style={style} src={url} alt="bilde" />
    </StyledTop>
  );
};

const StyledTop = styled(animated.div)`
  margin-top: 66px;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    padding: 0 3em;
    object-fit: contain;
    object-position: center;
  }

  @media all and (min-width: 600px) {
    img {
      padding: 0;
    }
  }

  @media all and (max-width: 1280px) {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default VeienTilSolgt;
