import React from 'react';
import styled from 'styled-components';

interface IButtonGroup extends React.HTMLAttributes<HTMLDivElement> {}

const ButtonGroupGrid: React.FC<IButtonGroup> = ({
  className,
  children,
  style
}) => {
  return (
    <ButtonGroupGridStyle style={style} className={className}>
      {children}
    </ButtonGroupGridStyle>
  );
};
const ButtonGroupGridStyle = styled.div`
  display: grid;
  grid-template-columns: max-content;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  justify-content: center;

  @media (min-width: 600px) {
    &.col-2 {
      grid-template-columns: max-content max-content;
    }
    &.col-3 {
      grid-template-columns: max-content max-content max-content;
    }
    &.col-4 {
      grid-template-columns: repeat(4, max-content);
    }
  }
`;

export default ButtonGroupGrid;
